.not_found {
   margin-top: 50px;
}

.not_found_content {
   width: 100%;
   display: flex;
   justify-content: center;
}

.link_404 {
   @extend .not_found_content;
}

.image404 {
   max-width: 640px;
   height: auto;

   img {
      width: 100%;
   }
}

.btn {
   @include button();
   width: 150px;
   background-color: $primary;

   &:hover {
      background-color: $secondary;
   }
}

.text_not_found {
   margin-top: 50px;
   font-size: 25px;
   background-color: white;
   height: 100px;
   border-radius: 25px;
   line-height: 100px;
   width: 300px;
   text-align: center;
}

@media #{$medium} {
   .not_found_content {
      @include verticalCenter();
   }
}