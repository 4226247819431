@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

.sidebar {
   min-width: 240px;
   min-height: 570px;
   position: fixed;
   height: 100vh;
   background-color: $primary;
   border-radius: 0 25px 25px 0;
   color: white;
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: 100vh 1fr 110px;
}

.sidebar_content {
   height: 100%;
   position: relative;
   width: auto;
   overflow-y: auto;
   display: grid;
   grid-template-columns: 1fr;
   grid-template-areas:
      "1"
      "2"
      "3";
}

.head {
   grid-area: 1;
   text-align: center;

   h2 {
      margin-top: $spacing3;
      letter-spacing: $spacing1;
   }
}

.img_sidebar {
   border-radius: 50%;
   margin-top: $spacing3;
}

.menu {
   grid-area: 2;
   padding: 2rem;

   ul {
      height: 100%;
      display: grid;
      text-align: center;
   }

   li {
      align-self: center;
      font-size: 1.2rem;
      padding: 10px;
   }

   a {
      color: white;
      padding: 10px;
      border-radius: 25px;
   }

   a.active {
      color: $secondary;
   }

   a:hover {
      background-color: $tertiary;
   }
}

.icons_sidebar {
   p {
      font-family: 'Sacramento';
      font-size: $h2Size;
      margin: $spacing4 auto $spacing4;
      width: 40px;
      text-align: center;
   }
}

.fa_sidebar {
   display: flex;
   justify-content: space-evenly;

   i {
      font-size: 2rem;
      color: white;

      &:hover {
         color: $secondary;
      }
   }
}

@media #{$medium} {
   .sidebar {
      position: relative;
      display: flex;
      min-height: 50px;
      height: auto;
      border-radius: inherit;
      border-bottom: 1px solid $secondary;
   }

   .sidebar_content {
      overflow-y: inherit;
      width: 100%;
   }

   .head, .icons_sidebar {
      display: none;
   }

   .menu {
      padding: 0.9rem;
      
      ul {
         display: flex;
         justify-content: space-between;
      }
   }
}

@media #{$small} {
   .sidebar {
      width: 100%;
      align-items: center;
      background-color: transparent;
   }

   .sidebar_content {
      position: unset;
      display: block;
   }

   .header_burger {
      @include alignCenter();
      width: 35px;
      height: 35px;
      position: absolute;
      top: 0.5rem;
      margin-left: $spacing4;
      background-color: $primary;
      border-radius: 50%;
      padding: 0;
      border: none;
      cursor: pointer;

      &:hover {
         .header_icon {
            background-color: $secondary;
         }
      }
   }
  
   .header_icon {
      content: '';
      position: relative;
      width: 15px;
      height: 2px;
      background-color: white;
      display: block;
      transition: .5s;
      transition-property: top,bottom,transform;

      &::before, &::after {
         @extend .header_icon
      }

      &::before {
         bottom: -4px;
      }
      
      &::after {
         top: -6px;
      }
   }

   .header_icon_anim {
      transform: rotate(-135deg);
      background-color: transparent;

      &::before {
         bottom: 0;
         transform: rotate(-90deg);
      }

      &::after {
         top: -2px;
         transform: rotate(0);
      }
   }

   .menu {
      background-color: $primary;
      margin-top: -238px;

      ul {
         display: flex;
         flex-direction: column;
         height: 160px;
      }
   }

   .menu_show {
      transition: margin-top 1s;
      margin-top: 0;
   }
}