@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500');

/************************  COLOR  ************************/
$bodyColor:#eff0f4;
$primary: rgb(7, 89, 102);
$secondary: rgb(29, 161, 242);
$tertiary: rgba(29, 161, 242, 0.1);
$textColor: black;
$errorForm: rgb(224, 24, 24);
$successForm: rgb(0, 158, 0);

/************************  FONTS  ************************/
$font: 'Roboto', sans-serif;
$h1Size: 2.5rem;
$h2Size: 1.3rem;
$h3Size: 1.1rem;

/************************  SPACING  ************************/
$spacing1: .2rem;
$spacing2: .4rem;
$spacing3: .8rem;
$spacing4: 1.6rem;
$spacing4: 2rem;

/************************  MIXINS  ************************/
@mixin alignCenter {
   display: flex;
   justify-content: center;
   align-items: center;
}

@mixin verticalCenter {
   display: flex;
   flex-direction: column;
   align-items: center;
}

@mixin button {
   height: 35px;
   color: white !important;
   border-radius: 10px;
   line-height: 35px;
   text-align: center;
   cursor: pointer;
}

/************************  MEDIA-QUERIES  ************************/
$medium: "only screen and (max-width: 1000px)";
$small: "only screen and (max-width: 620px)";


body {
   font-family: $font;
   font-weight: 200;
   color: $textColor;
   background-color: $bodyColor;
}

li {
   list-style-type: none;
}

.home, .portfolio, .competences, .contact{
   display: flex;
   min-height: 100vh;

   @media #{$medium} {
      display: block;
   }
}

.portfolio_content, .comp_content, .contact_content {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
}

h1 {
   font-size: $h1Size;
}

h2 {
   font-size: $h2Size;
}

h3 {
   font-size: $h3Size;
}